
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import NewPasswordForm from '@/components/forms/profile/CreateNewPasswordForm.vue';
import { Actions, Getters } from '@/store/enums/AuthEnums';
import toasts from '@/utils/toasts';

export default defineComponent({
  name: 'create-new-password',
  components: {
    NewPasswordForm,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({
      actionErrors: Getters.GET_AUTH_USER_ACTION_ERROR,
    }),
  },
  methods: {
    ...mapActions({
      newPassword: Actions.NEW_PASSWORD,
    }),
    handleSubmit(values) {
      if (this.loading) return;
      this.loading = true;

      this.newPassword(values)
        .then(() => {
          (this.$refs.newPasswordForm as any).resetForm();
          this.loading = false;
          toasts.success('Password Successfully Saved!');
        })
        .catch(() => {
          this.loading = false;
          const { errors, message } = this.actionErrors;
          toasts.error(errors, message);
        });
    },
  },
});
